<template>
    <div class="container">
        <div class="login-form">
            <div class="log"><img src="../static/login-panel.png" /></div>
            <div class="main">
                <a-form id="formlogin" :form="form" class="user-layout-login"    >
                    <a-form-item>
                        <h3 class="title">
                            资源管理系统
                        </h3>


                    </a-form-item>
                    <a-form-item>
                        <a-input  v-decorator="[
          'username',
          { rules: [{ required: true, message: '请输入账号' }] },
        ]"  size="large" type="text" placeholder="账号" ></a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-input-password v-decorator="[
          'password',
          { rules: [{ required: true, message: '请输入密码' }] },
        ]"  size="large" @keydown.enter="handleSubmit" type="text" placeholder="密码" ></a-input-password>
                    </a-form-item>
                    <a-form-item>
                        <a-button  size="large" style="width: 240px;border-radius: 15px;background-color: black" type="primary" @click="handleSubmit" class="login-button">登录</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        name: "login",
        data(){
            return {
                LoginModel:{
                    username:'',
                    password:''
                },
                form: this.$form.createForm(this, { name: 'dynamic_rule' }),
            }
        },
        methods:{
            handleSubmit(){
                let that=this;
                that.form.validateFields((err,values) => {


                   that.LoginModel.username=values.username;
                   that.LoginModel.password=values.password;
                    if (!err||err==null) {
                        that.$axios.post("/api/Admin/Login", that.LoginModel).then((res) => {
                            if (res.data.message){
                                that.$store.commit('setUser',res.data.data);
                                    that.$router.push({name:'home'});
                            }else{
                                that.$message.error('登录失败');
                            }

                        });
                    }
                });
            }
        }
    }
</script>


<style lang="less" scoped>
    .login-header{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:auto;
        width: 400px;
        height: 150px;
        h1{
           color: white;
            font-size: 36px;
            line-height: 36px;
            text-align: center;
        }
    }
    .title{
        color: black;
        text-align: center;
        font-weight: bold;
        font-size: 22px;
    }
    .container{
        height: 1024px;
        display: flex;

        background-image:url("../static/login-bg.png") ;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .login-form{

        height: 466px;
        display: flex;
        background-color: #fff;
        width:850px;

        border: 1px solid #d9d9d9;
        border-radius: 15px;

        .log{
            padding: 60px;
            width: 499px;
            height: 466px;
            img{
                width: 100%;
            }

        }
        .main{
            align-items: center;
            display: flex;
            margin-left:50px;
            width: 332px;

        }
    }

.user-layout-login{
    font-size: 14px;
}
    .login-button{
        font-size: 14px;
        padding: 0 15px;
        height: 35px;
    }
</style>